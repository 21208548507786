.guest-checklist-page {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .guest-checklist-page .table {
    margin-top: 20px;
  }
  
  .guest-checklist-page .btn-link {
    color: #FFFFFF;
    margin-left: 10px;
  }
  
  .guest-checklist-page .fa-edit,
  .guest-checklist-page .fa-trash {
    font-size: 1.2em;
  }
  
  .chevron-icon {
    font-size: 18px;
  }
  