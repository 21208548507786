.delete-account-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .delete-account-container h1 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .delete-account-container ul {
    margin: 20px 0;
    padding-left: 20px;
  }
  
  .delete-account-container ul li {
    margin-bottom: 10px;
  }
  
  .delete-account-container h2 {
    font-size: 20px;
    margin-top: 20px;
  }
  
  .delete-account-container p {
    line-height: 1.6;
    font-size: 16px;
    color: #333;
  }
  