.container {
    padding: 16px;
    background-color: #f5f5f5;
    min-height: 100vh;
  }
  
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loader {
    font-size: 24px;
    color: #733BFF;
  }
  
  .conversation-container {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: relative;
  }
  
  .unread-conversation {
    background-color: #e0f7fa;
  }
  
  .conversation-text {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  .message-text {
    font-size: 14px;
    color: #666;
  }
  
  .timestamp-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
  }
  
  .timestamp-text {
    font-size: 12px;
    color: #999;
  }
  
  .unread-badge {
    position: absolute;
    top: 16px;
    right: 16px;
    background-color: #ff0000;
    border-radius: 12px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .unread-badge-text {
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
  }
  
  .empty-text {
    text-align: center;
    color: #666;
    font-size: 16px;
    margin-top: 20px;
  }
  