.registration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f7f7f7;
}

.registration-box {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.registration-box h2 {
  font-size: 28px;
  color: #2c3e50;
  margin-bottom: 20px;
}

.input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
}

.input:focus {
  border-color: #733BFF;
  outline: none;
  box-shadow: 0 0 8px rgba(115, 59, 255, 0.2);
}

.register-button {
  width: 100%;
  padding: 12px;
  background-color: #733BFF;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.register-button:hover {
  background-color: #5a2fcc;
}

.login-button {
  width: 100%;
  padding: 12px;
  margin-top: 10px;
  background-color: #E5DBFF;
  color: #733BFF;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #D1C0FF;
}

/* Add media query for smaller screens */
@media (max-width: 768px) {
  .registration-box {
    padding: 20px;
  }

  .registration-box h2 {
    font-size: 24px;
  }

  .input {
    font-size: 14px;
  }
}
