.edit-profile-container {
  position: relative;
  padding: 20px;
  background-color: #f9fafb;
  min-height: 100vh;
}

.skip-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: #733BFF;
  font-size: 16px;
  cursor: pointer;
}

.skip-button:hover {
  text-decoration: underline;
}

.edit-profile-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.profile-picture-container {
  position: relative;
}

.profile-picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}

.upload-input {
  position: absolute;
  width: 120px;
  height: 120px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.edit-profile-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
  text-align: left; /* Added to align form elements to the left */
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  color: #333;
}

.form-group input[type="email"],
.form-group input[type="text"],
.form-group select {
  width: 100%;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  font-size: 14px;
  color: #495057;
}

.form-group .checkbox {
  width: 5%;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #80bdff;
  outline: none;
}

/* Updated styles for checkbox alignment */
.checkbox-group {
  margin-bottom: 20px; /* Ensure consistent spacing */
}

.checkbox-group label {
  align-items: center;
  justify-content: flex-start; /* Align items to the left */
  font-weight: 600;
  font-size: 14px;
  color: #333;
  width: 100%; /* Ensure label spans full width */
}

.checkbox-group input {
  margin-right: 8px;
}

.update-button {
  width: 100%;
  padding: 12px;
  background-color: #733BFF;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.update-button:hover {
  background-color: #321872;
}

.loader {
  text-align: center;
  padding: 50px;
  font-size: 18px;
}

.react-select-container {
  font-size: 14px;
}

.react-select__control {
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.react-select__control--is-focused {
  border-color: #80bdff;
  box-shadow: none;
}

.react-select__menu {
  z-index: 100;
}
