.response-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eaeaea;
}

.response-label {
  flex: 1;
  font-weight: bold;
  margin-right: 10px;
}

.response-value {
  flex: 2;
}

.checklist-container {
  margin-top: 20px;
}

.checklist-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checklist-text {
  margin-left: 10px;
}

.organizer-note {
  margin-top: 20px;
}

.attended-container {
  margin-top: 20px;
}

.loader {
  text-align: center;
  padding: 20px;
}

.guest-details h5 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #AB8BFC;
}


.modal-footer .btn:last-child {
  margin-right: 0;
}
