.event-view-container {
  padding: 20px;
}

.loader {
  text-align: center;
}

.events-list {
  display: flex;
  flex-direction: column;
}

.event-item {
  background: #ffffff;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.event-item:hover {
  background: #f0f0f0;
}

.event-item h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.event-item p {
  margin: 5px 0;
  font-size: 14px;
  color: #666;
}

.add-event-btn {
  margin-bottom: 10px;
}
