.notifications-container {
    padding: 20px;
  }
  
  .mark-all-read-button {
    margin-bottom: 16px;
    padding: 10px 20px;
    background-color: #733BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .notifications-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .notification-item {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .notification-item.unread {
    font-weight: bold;
  }
  
  .notification-text {
    flex: 1;
    color: #333;
  }
  
  .status-container {
    display: flex;
    align-items: center;
  }
  
  .icon-container {
    display: flex;
    gap: 10px;
  }
  
  .status-icon-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }
  
  .status-text {
    font-style: italic;
  }
  
  .status-green {
    color: #4caf50;
  }
  
  .status-red {
    color: #f44336;
  }
  
  .loader {
    text-align: center;
    font-size: 18px;
    color: #666;
  }
  