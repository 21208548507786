.option-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .option-group input {
    flex: 1;
    margin-right: 10px;
  }
  