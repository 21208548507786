.tasks-container {
  padding: 20px;
}

.filters-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filters-container select,
.filters-container input[type="date"] {
  padding: 5px;
}

.filters-container button {
  padding: 5px 10px;
  cursor: pointer;
}

.tasks-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.task-item {
  background: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.task-item.dragging {
  background: #e0e0e0;
}

.task-item.hovered {
  background: #e0f7fa;
  cursor: pointer;
}

.task-item-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.task-item-title {
  font-weight: bold;
  flex-grow: 1;
}

.task-item-details {
  margin-top: 10px;
}

.add-task-button {
  margin-bottom: 20px;
  padding: 10px 20px;
  background: #733BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-task-button i {
  margin-right: 10px;
}

.edit-task-button {
  background: none;
  border: none;
  cursor: pointer;
}

.loader {
  text-align: center;
  padding: 20px;
}

.drag-handle {
  cursor: grab;
  margin-left: auto;
}

.create-template-button {
    margin-bottom: 20px;
    margin-left: 20px;
    padding: 10px 20px;
    background: #733BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .create-template-button i {
    margin-right: 10px;
  }