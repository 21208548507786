.team-members-page {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .profile-picture-container {
    width: 50px;
    height: 50px;
  }
  
  .profile-picture {
    width: 100%;
    height: 50px;
    object-fit: cover;
}
  
  .loader {
    text-align: center;
    padding: 50px 0;
  }
  