.event-detail-container {
  padding: 20px;
  background-color: #f5f5f5;
}

/* Event Header */
.event-header {
  display: flex;
  justify-content: space-between; /* Align title and buttons on the same line */
  align-items: center;
  margin-bottom: 20px;
}

.event-header h2 {
  font-size: 24px;
  margin: 0;
}

.button-container {
  display: flex;
  gap: 10px; /* Adds space between buttons */
}

.event-edit-btn, .event-delete-btn {
  background-color: #733bff;
  border: none;
  padding: 8px 12px;
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-edit-btn:hover {
  background-color: #321872;
}

.event-delete-btn {
  background-color: #ff3b65 !important;
}

.event-delete-btn:hover {
  background-color: #cc2e50 !important;
}

.detail-item {
  margin-bottom: 10px;
}

.detail-item strong {
  display: inline-block;
  width: 100px;
}

.detail-item span {
  color: #555;
}

.counters-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Allows wrapping on small screens */
  margin-top: 20px;
}

.counter-item {
  cursor: pointer;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-right: 10px;
  text-align: center;
  flex: 1 1 45%; /* Takes half the space on mobile screens */
  margin-bottom: 10px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1); /* Adds some depth */
}

.counter-item:last-child {
  margin-right: 0;
}

.counter-item strong {
  display: block;
  margin-bottom: 5px;
}

.counter-item span {
  font-size: 16px;
  color: #333;
}

.counter-item:hover {
  background-color: #e0e0e0;
}

.charts-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap; /* Allows charts to wrap on small screens */
}

.chart {
  flex: 1 1 100%; /* Ensures each chart takes full width on smaller screens */
  max-width: 400px;
}

.chart:hover {
  background-color: #e0e0e0;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .event-detail-container {
    padding: 10px;
  }

  .event-detail-container h2 {
    font-size: 20px;
  }

  .counters-container {
    flex-direction: column;
  }

  .counter-item {
    flex: 1 1 100%; /* Full width on mobile devices */
    margin-right: 0;
  }

  .charts-container {
    flex-direction: column; /* Stack charts vertically on mobile */
    gap: 10px;
  }

  .chart {
    flex: 1 1 100%; /* Full width on mobile screens */
    max-width: 100%;
  }

  .button-container {
    flex-direction: column; /* Stack buttons vertically on mobile */
    gap: 10px; /* Adds space between buttons on mobile */
  }
}
