/* Default sidebar styles for desktop */
.sidebar {
  width: 200px;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.sidebar .nav-link {
  color: #333;
}

.sidebar .nav-link:hover {
  text-decoration: underline;
}

/* Sidebar toggle button */
.sidebar-toggle {
  background-color: transparent;
  border: none;
  font-size: 24px;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1001;
  cursor: pointer;
  display: none; /* Hidden on desktop */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  /* Hide the sidebar by default */
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
    background-color: #fff;
    padding-top: 50px; /* To leave space for the toggle button */
    transform: translateX(-100%); /* Hide the sidebar */
    z-index: 1000;
  }

  /* When the sidebar is open */
  .sidebar.open {
    transform: translateX(0);
  }

  /* Show the toggle button on mobile */
  .sidebar-toggle {
    display: block;
    position: fixed;
    top: 15px;
    left: 15px;
    background-color: #fff;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 1100;
  }
}

/* Event detail container adjustments for mobile */
.event-detail-container {
  display: flex;
  flex-direction: column; /* Stack content for mobile */
  padding: 10px;
}

.event-content {
  flex: 1;
  padding: 20px;
  margin-top: 60px; /* Ensure content is visible under the toggle button */
}

@media (min-width: 769px) {
  .event-detail-container {
    flex-direction: row; /* Keep sidebar and content side by side on desktop */
  }

  .event-content {
    margin-top: 0;
  }
}
