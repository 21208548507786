.not-found-container {
    text-align: center;
    padding: 50px;
    font-family: 'Montserrat', sans-serif;
    color: #2c3e50;
  }
  
  .not-found-title {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .not-found-message {
    font-size: 24px;
    margin-bottom: 30px;
  }
  
  .back-home-link {
    font-size: 18px;
    color: #733BFF;
    text-decoration: none;
    font-weight: bold;
  }
  
  .back-home-link:hover {
    color: #321872;
  }
  