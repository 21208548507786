.settings-container {
    padding: 20px;
  }
  
  .menu-block {
    margin-bottom: 20px;
  }
  
  .block-title {
    font-size: 18px;
    color: grey;
    margin-bottom: 10px;
  }
  
  .item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
  }
  
  .icon-container {
    width: 30px;
    display: flex;
    justify-content: center;
  }
  
  .text {
    margin-left: 10px;
    flex: 1;
  }
  
  .chevron-icon {
    color: grey;
  }
  
  .logout-text {
    color: red;
  }
  