.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 60px;
  }
  
  .modal.show {
    display: block;
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    border-radius: 10px;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .modal-header h2 {
    margin: 0;
  }
  
  .modal-header .close {
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .modal-body {
    margin-bottom: 20px;
  }
  
  .modal-body .form-group {
    margin-bottom: 15px;
  }
  
  .modal-body .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .modal-body .form-group input,
  .modal-body .form-group textarea,
  .modal-body .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-footer button {
    padding: 10px 20px;
    background: #733BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  