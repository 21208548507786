.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .switch-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .specialization-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin-bottom: 20px;
  }
  
  .update-button, .remove-button, .add-button {
    padding: 10px;
    margin-bottom: 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .update-button {
    background-color: #733BFF;
    color: white;
  }
  
  .remove-button {
    background-color: #ff4d4d;
    color: white;
    margin-left: 10px;
  }
  