.guests-container {
  padding: 20px;
}

/* Adjust heading and paragraph */
.guests-container h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.guests-container p {
  font-size: 16px;
  margin-bottom: 20px;
}

/* Search container */
.search-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.search-input {
  flex: 1 1 100%;
  padding: 5px;
  border: 1px solid #733BFF;
  border-radius: 4px;
  background-color: "#FFFFFF";
  color: var(--text-color);
  margin-bottom: 10px;
}

.filter-select {
  flex: 1 1 100%;
  width: 100%;
  margin-bottom: 10px;
}

@media (min-width: 600px) {
  .search-container {
    flex-wrap: nowrap;
  }

  .search-input {
    flex: 1;
    margin-bottom: 0;
  }

  .filter-select {
    flex: 0 0 auto;
    width: 200px;
    margin-left: 10px;
    margin-bottom: 0;
  }
}

.mark-attended-button {
  margin-bottom: 16px;
  padding: 10px 15px;
  background-color: #733BFF;
  border: none;
  border-radius: 8px;
  color: #FFFFFF;
  cursor: pointer;
  text-align: center;
}

.mark-attended-button.active {
  background-color: #E5DBFF;
  color: #733BFF;
}

.guests-list {
  margin-top: 20px;
}

.guest-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 8px;
  flex-wrap: wrap;
}

.guest-item-details {
  display: flex;
  align-items: center;
  flex: 1 1 100%;
  margin-bottom: 10px;
}

.guest-item-details span {
  margin-left: 8px;
  color: #000000;
}

.guest-item-actions {
  display: flex;
  align-items: center;
  flex: 1 1 100%;
}

.guest-item-actions .guest-item-button {
  flex: 1;
  margin-right: 5px;
}

@media (min-width: 600px) {
  .guest-item {
    flex-wrap: nowrap;
  }

  .guest-item-details {
    flex: 1;
    margin-bottom: 0;
  }

  .guest-item-actions {
    flex: 0 0 auto;
  }

  .guest-item-actions .guest-item-button {
    margin-right: 0;
    margin-left: 10px;
  }
}

.guest-item-button {
  padding: 5px 10px;
  background-color: #733BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.guest-item-button:hover {
  background-color: #004f5a;
}

.invite-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #733BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.invite-button:hover {
  background-color: #004f5a;
}

.loader {
  text-align: center;
  padding: 50px 0;
}

.attended-icon {
  color: #AB8BFC;
}

.empty-icon-space {
  width: 20px; /* Space to align text properly when no icon */
}
