/* Ensure the body and html take up the full height of the viewport */
html, body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}

/* The main container takes up all available space minus the footer */
.main-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Footer styles */
.footer {
    padding: 20px;
    background-color: #f5f5f5;
    text-align: center;
    width: 100%;
    position: relative; /* Allow it to stay fixed to the bottom of the content */
}

.footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.footer-section {
    flex: 1;
    padding: 10px;
    min-width: 250px;
}

.footer-section h3 {
    margin-bottom: 10px;
    font-size: 18px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 8px;
}

.footer-section ul li a {
    color: #555;
    text-decoration: none;
}

.footer-section ul li a:hover {
    text-decoration: underline;
}

.footer-item {
    font-size: 10px;
    color: #555;
}

.contact-us-btn {
    margin-top: 10px;
    background-color: #733BFF;
    color: #fff;
    border: none;
    padding: 8px 15px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
}

.contact-us-btn:hover {
    background-color: #321872;
}

.footer-banners img {
    max-width: 25%; /* This makes the image 50% smaller */
    filter: grayscale(100%); /* This applies the black-and-white effect */
    margin-top: 20px;
}

/* Responsive design */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
    }
    .footer-banners img {
        max-width: 80%;
    }
}
