.reset-password-container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #FFF;
}

.reset-password-form {
    width: 100%;
    max-width: 500px;
    padding: 40px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.reset-password-form h2 {
    text-align: center;
    margin-bottom: 30px;
}

.reset-password-form .form-label {
    font-weight: bold;
}

.reset-password-form .btn-primary {
    width: 100%;
    padding: 10px;
    font-size: 16px;
}

.reset-password-form .alert {
    text-align: center;
}
