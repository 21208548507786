.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background-color: #f7f7f7;
}

.login-box {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.login-box h2 {
  font-size: 28px;
  color: #2c3e50;
  margin-bottom: 20px;
}

.login-box input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
}

.login-box input:focus {
  border-color: #733BFF;
  outline: none;
  box-shadow: 0 0 8px rgba(115, 59, 255, 0.2);
}

.login-btn {
  width: 100%;
  padding: 12px;
  background-color: #733BFF;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.login-btn:hover {
  background-color: #5a2fcc;
}

.secondary-btn {
  width: 100%;
  padding: 12px;
  margin-top: 10px;
  background-color: #E5DBFF;
  color: #733BFF;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.secondary-btn:hover {
  background-color: #D1C0FF;
}

.link-btn {
  background: none;
  border: none;
  color: #733BFF;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
}

.link-btn:hover {
  text-decoration: underline;
}

/* Add media query for smaller screens */
@media (max-width: 768px) {
  .login-box {
    padding: 20px;
  }
}
