.agenda-page {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .agenda-page .table {
    margin-top: 20px;
  }
  
  .agenda-page .btn-link {
    color: #FFFFFF;
    margin-left: 10px;
  }
  
  .agenda-page .fa-edit,
  .agenda-page .fa-trash {
    font-size: 1.2em;
  }
  
  .agenda-page .modal-body .form-group {
    margin-bottom: 1rem;
  }
  
  .chevron-icon {
    font-size: 18px;
  }
  