.users-container {
    padding: 20px;
  }
  
  .search-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .search-container input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .search-container button {
    padding: 10px 20px;
    background-color: #733BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .selected-filters .filter-tag {
    display: inline-block;
    background-color: #733BFF;
    color: #fff;
    padding: 5px 10px;
    border-radius: 15px;
    margin-right: 5px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .users-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .user-item {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 400px;
    cursor: pointer;
  }
  
  .user-item .profile-picture {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    object-fit: cover;
  }
  
  .user-details {
    flex: 1;
    margin-left: 10px;
  }
  
  .user-details p {
    margin: 0 0 5px 0;
    font-size: 14px;
  }
  
  .icon-buttons {
    display: flex;
    gap: 10px;
  }
  
  .icon-buttons button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .icon-buttons i {
    font-size: 20px;
  }
  
  .load-more {
    text-align: center;
    margin-top: 20px;
  }
  
  .load-more button {
    padding: 10px 20px;
    background-color: #733BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .no-users {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    color: #666;
  }
  
  .specialization-group {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .apply-filters-button,
  .clear-filters-button {
    margin-top: 10px;
  }
  