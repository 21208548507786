.view-template-container {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
}

.tasks-list {
  display: grid;
  gap: 10px;
}

.task-item {
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.2s ease;
}

.task-item.dragging {
  background-color: #e6f7ff;
}

.drag-handle {
  cursor: grab;
}

.ms-2 {
  margin-right: 10px;
}
