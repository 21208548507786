.upgrade-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.limits-comparison {
    margin-bottom: 20px;
}

.limits-table {
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;
}

.limits-row {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.limits-column {
    flex: 1;
    text-align: center;
}

.premium-description {
    font-style: italic;
    text-align: center;
}

.upgrade-field {
    margin-bottom: 20px;
}

.upgrade-field label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.upgrade-field input,
.upgrade-field select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.upgrade-button {
    width: 100%;
    padding: 12px;
    background-color: #733bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
}

.upgrade-button:hover {
    background-color: #321872;
}

.pricing-section {
    margin-top: 20px;
}

.pricing-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.pricing-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    width: 150px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.pricing-currency {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.pricing-amount {
    font-size: 16px;
}

.terms-notice {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
    font-style: italic;
}

.terms-link {
    color: #733bff;
    text-decoration: underline;
}

.terms-link:hover {
    color: #321872;
}
  
