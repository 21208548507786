.form-blocks-manager {
  margin-top: 20px;
}

.loader {
  text-align: center;
  padding: 20px;
}

.blocks-list {
  margin-top: 20px;
}

.block-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd; /* Ensure full border */
    border-radius: 8px;
    background-color: #f9f9f9;
    border-top-width: 1px !important; /* Add border top */
  }  

.block-details {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.block-content {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align drag icon, title, and actions vertically */
}

.block-header {
  display: flex;
  align-items: center;
}

.grip-icon {
  margin-right: 10px;
  cursor: grab;
  font-size: 18px;
  color: #aaa;
}

.block-label {
  margin-bottom: 5px; /* Space between label and title */
  font-size: 12px;
  color: #AB8BFC;
}

.event-block-title {
  margin-bottom: 0;
  font-size: 16px; /* Ensure consistent font size for the title */
}

.block-actions {
  display: flex;
  align-items: center;
}

.block-actions .form-check {
  margin-right: 10px;
}

.block-actions .btn {
  margin-left: 5px;
}

.block-type-button {
    margin-left: 10px;
    margin-bottom: 10px;
}
