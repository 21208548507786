.templates-container {
    padding: 20px;
  }
  
  .filters-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .filters-container input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .filters-container button {
    padding: 10px 20px;
    background-color: #733BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .selected-filters .filter-tag {
    display: inline-block;
    background-color: #733BFF;
    color: #fff;
    padding: 5px 10px;
    border-radius: 15px;
    margin-right: 5px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .templates-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .template-item {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
  }
  
  .template-item.hover {
    background-color: #f0f0f0;
  }
  
  .template-details h3 {
    margin: 0 0 10px 0;
    font-size: 18px;
  }
  
  .template-actions {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .template-actions button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .template-actions i {
    font-size: 20px;
    color: #dc3545;
  }
  
  .load-more {
    text-align: center;
    margin-top: 20px;
  }
  
  .load-more button {
    padding: 10px 20px;
    background-color: #733BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .no-templates {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    color: #666;
  }
  