.top-menu {
    border-bottom: 1px solid #ddd;
  }
  
  .top-menu .nav-link {
    margin-left: 20px;
    color: #333;
  }
  
  .top-menu .btn-outline-danger {
    margin-left: 20px;
  }
  
  .top-menu .btn-outline-danger:hover {
    background-color: #733BFF;
    color: white;
  }
  
  @media (max-width: 991px) {
    .top-menu .navbar-collapse {
      overflow-y: auto;
      max-height: 300px;
    }
  }
  