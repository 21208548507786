/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

body {
  background-color: #f7f9fc;
}

/* Container for the entire event page */
.event-page {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.event-container {
  max-width: 1200px;
  width: 100%;
  background: #fff;
  border-radius: 12px;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.event-title {
  font-size: 2em;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 30px;
  font-weight: bold;
}

/* Event Details */
.event-details {
  text-align: center;
  margin-bottom: 40px;
}

.event-date-time {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.event-date,
.event-time,
.event-location {
  font-size: 1.1em;
  color: #555;
}

.event-registration-success {
  color: #28a745;
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 20px;
}

.event-closed-text {
  color: #e74c3c;
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 20px;
}

/* Sections */
section {
  margin-bottom: 50px;
}

.section-title {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 20px;
  text-align: left;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  font-weight: bold;
}

/* Agenda Styles */
.agenda-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.agenda-item {
  display: flex;
  gap: 20px;
  padding: 20px;
  background: #f9f9f9;
  border-left: 4px solid #733BFF;
  border-radius: 8px;
}

.agenda-time {
  font-size: 1em;
  color: #2c3e50;
  flex-shrink: 0;
  width: 120px;
}

.agenda-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.agenda-title {
  font-size: 1.1em;
  font-weight: bold;
  color: #2c3e50;
}

.agenda-description {
  font-size: 1em;
  color: #7f8c8d;
}

/* Team Members Styles */
.team-list {
  list-style: none;
  padding: 0;
}

.team-member {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
}

.team-member-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 25px;
  object-fit: cover;
}

.team-member-info {
  display: flex;
  flex-direction: column;
  color: #2c3e50;
  font-size: 1em;
}

.team-member-info strong {
  font-size: 1.2em;
}

/* Registration Form Styles */
.registration-form {
  background-color: #f7f7f7;
  padding: 30px;
  border-radius: 8px;
}

.registration-form .form-group {
  margin-bottom: 20px;
}

.registration-form .form-group label {
  font-weight: bold;
  color: #333;
}

/* Apply styles only to .form-control class */
.registration-form .form-control {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.registration-form .form-control:focus {
  border-color: #733BFF;
  outline: none;
}

/* Ensure checkboxes and radios are displayed correctly */
.registration-form .form-check {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.registration-form .form-check-input {
  width: auto;
  margin-right: 10px;
}

.registration-form .form-check-label {
  margin-bottom: 0;
}

/* Name and Companion Fields Row */
.name-companion-row {
  display: flex;
  align-items: center;
}

/* Input field takes remaining space */
.name-companion-row .form-control {
  flex-grow: 1;
}

/* Action elements */
.name-companion-row .add-companion-btn,
.name-companion-row .companion-actions {
  flex-shrink: 0;
  margin-left: 10px;
  min-width: 120px;
}

/* Style for Add Companion Button */
.registration-form .add-companion-btn {
  background-color: #733BFF;
  color: white;
  padding: 10px 12px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  border: none;
}

.registration-form .add-companion-btn:hover {
  background-color: #321872;
}

/* Companion Actions (Checkbox and Remove Icon) */
.companion-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.companion-actions .form-check {
  margin-bottom: 0;
}

.companion-actions .remove-companion-icon {
  font-size: 1.2em;
  cursor: pointer;
  margin-left: 10px;
}

/* Register Button */
.register-btn-wrapper {
  display: flex;
  justify-content: center;
}

.registration-section .register-btn {
  padding: 12px;
  background-color: #733BFF;
  color: #fff;
  border: none;
  font-size: 1.1em;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

.registration-section .register-btn:hover {
  background-color: #321872;
}

/* Contact Points */
.contact-points-list {
  list-style: none;
  padding: 0;
}

.contact-point {
  margin-bottom: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
}

.contact-name {
  font-weight: bold;
  font-size: 1.2em;
}

.contact-role,
.contact-phone,
.contact-email {
  font-size: 1em;
  color: #555;
}

/* Loader */
.loader {
  text-align: center;
  padding: 50px 0;
}

.paragraph-section, .registration-section {
  color: #666;
  line-height: 1.6;
}

.header-section {
  margin-bottom: 0;
}

button[type='button'] {
  background-color: #733BFF;
  color: white;
  border: none;
}

button[type='button']:hover {
  background-color: #321872;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .event-container {
    padding: 20px;
  }

  .event-title {
    font-size: 2em;
  }

  .section-title {
    font-size: 1.5em;
  }

  .agenda-item {
    flex-direction: column;
  }

  .agenda-time {
    width: auto;
  }

  .team-member {
    flex-direction: column;
    align-items: center;
  }

  .team-member-avatar {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .team-member-info {
    text-align: center;
  }

  /* Adjustments for Companion Fields on Mobile */
  .name-companion-row {
    flex-direction: column;
    align-items: stretch;
  }

  .name-companion-row .add-companion-btn,
  .name-companion-row .companion-actions {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }

  .companion-actions {
    justify-content: flex-start;
  }

  .companion-actions .remove-companion-icon {
    margin-left: auto;
  }

  .add-companion-btn {
    width: '100%'
  }

  .register-btn {
    width: 100%;
  }
}
