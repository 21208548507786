.contact-us-container {
    padding: 20px;
}

.contact-us-form {
    width: 100%;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #ffffff;
}

.contact-us-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #007bff;
}

.contact-us-form .form-label {
    font-weight: bold;
}

.contact-us-form .btn-primary {
    width: 100%;
    padding: 10px;
    font-size: 16px;
}

.contact-us-form .alert {
    text-align: center;
}
