.invite-form-page {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .loader {
    text-align: center;
    padding: 50px 0;
  }
  
  .form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .switch-container {
    display: flex;
    align-items: center;
  }
  
  .switch-container span {
    margin-right: 10px;
  }
  
  .form-content {
    margin-top: 20px;
  }
  
  .form-item {
    margin-bottom: 20px;
  }
  
  .event-url {
    display: flex;
    align-items: center;
  }
  
  .event-url .form-control {
    flex: 1;
    margin-right: 8px;
  }
  
  .event-url .btn {
    margin-left: 4px;
  }
  
  .chevron-icon {
    font-size: 18px;
  }
  