.team-container {
    padding: 20px;
  }
  
  .team-list {
    margin-top: 20px;
  }
  
  .team-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 10px 0;
  }
  
  .team-item .status-icon-container {
    width: 30px;
    text-align: center;
  }
  
  .team-item-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
  }
  
  .team-item-button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #733BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .team-item-button:hover {
    background-color: #004f5a;
  }
  
  .add-team-member-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #733BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-team-member-button:hover {
    background-color: #004f5a;
  }
  
  .status-icon {
    font-size: 20px;
  }
  
  .status-icon--active {
    color: green;
  }
  
  .status-icon--pending {
    color: orange;
  }
  
  .loader {
    text-align: center;
    padding: 50px 0;
  }
  