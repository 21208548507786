.messages-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.messages-list {
    flex-grow: 1;
    overflow-y: auto;
    padding: 16px;
    background-image: url('../../assets/4e0abc15-0695-445f-b22c-e47e9cf62128.png');
    background-size: cover;
    display: flex;
    flex-direction: column-reverse;
}

.message-container {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 80%;
}

.other-user-message {
    align-self: flex-start;
}

.current-user-message {
    align-self: flex-end;
    background-color: #dcf8c6;
}

.reply-container {
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
}

.reply-text {
    font-style: italic;
    color: #666;
}

.message-user {
    font-weight: bold;
    margin-bottom: 4px;
}

.message-text {
    font-size: 16px;
}

.image-container {
    position: relative;
}

.message-image {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    margin-top: 10px;
}

.image-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.message-timestamp-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.message-timestamp {
    font-size: 12px;
    color: #999;
    margin-top: 4px;
}

.message-actions {
    display: flex;
    align-items: center;
}

.loader {
    margin-right: 8px;
}

.reply-banner {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
    border-top: 1px solid #ccc;
}

.reply-banner-text {
    flex: 1;
    font-style: italic;
    color: #333;
}

.selected-image-container {
    display: flex;
    align-items: center;
    padding: 10px;
}

.selected-image {
    width: 100px;
    height: 100px;
    border-radius: 8px;
}

.remove-image-button {
    margin-left: 10px;
}

.input-container {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #cccccc;
    background-color: #ffffff;
}

.message-input {
    flex: 1;
    height: 40px;
    border: 1px solid #cccccc;
    border-radius: 20px;
    padding: 0 16px;
    margin-right: 8px;
}

.send-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: linear-gradient(135deg, #733BFF, #0a9396);
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.image-input {
    margin-right: 8px;
}

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-close-button {
    position: absolute;
    top: 40px;
    right: 20px;
    background: none;
    border: none;
    color: #ffffff;
    font-size: 30px;
    cursor: pointer;
}

.modal-spinner {
    position: absolute;
    z-index: 1;
}

.expanded-image {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
}

.likeButton {
    margin-right: 10px;
}
