.profile-container {
  position: relative;
  padding: 20px;
  background-color: #f9fafb;
  min-height: 100vh;
}

.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-image-container {
  position: relative;
  width: 120px;
  height: 120px;
  margin-right: 20px;
}

.profile-picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-name {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.profile-buttons {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.favorite-button,
.edit-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #733BFF;
  margin-left: 10px;
}

.favorite-button:hover,
.edit-button:hover {
  color: #321872;
}

.profile-info {
  margin-bottom: 15px;
  font-size: 16px;
  color: #495057;
}

.profile-info strong {
  font-weight: 600;
  color: #333;
  margin-right: 5px;
}

.loader {
  text-align: center;
  padding: 50px;
  font-size: 18px;
}

.account-upgrade-button {
  background-color: #733BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.account-upgrade-button:hover {
  background-color: #321872;
}
