.form-fields-page {
    padding: 20px;
  }
  
  .description-section,
  .companions-section {
    margin-bottom: 20px;
  }
  
  .loader {
    text-align: center;
    padding: 50px 0;
  }
  