.language-container {
    padding: 20px;
  }
  
  .item {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .text {
    margin-left: 10px;
    flex: 1;
  }
  