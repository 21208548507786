/* Styles to match the login form styles */
.modal-body .input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
}

.modal-body .input:focus {
  border-color: #733BFF;
  outline: none;
  box-shadow: 0 0 8px rgba(115, 59, 255, 0.2);
}

.modal-footer .btn {
  width: 100%; /* Ensures buttons take full width on mobile */
  padding: 12px;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 10px; /* Add spacing between buttons */
}

.modal-footer .btn-primary {
  background-color: #733BFF;
  color: white;
  border: none;
  cursor: pointer;
}

.modal-footer .btn-primary:hover {
  background-color: #5a2fcc;
}

.modal-footer .btn-secondary {
  background-color: #E5DBFF;
  color: #733BFF;
  border: none;
}

.modal-footer .btn-secondary:hover {
  background-color: #D1C0FF;
}

/* Ensure date/time picker inputs are styled similarly */
.react-datepicker-wrapper,
.react-datepicker__input-container input {
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
}

.react-datepicker__input-container input:focus {
  border-color: #733BFF;
  outline: none;
  box-shadow: 0 0 8px rgba(115, 59, 255, 0.2);
}

/* Responsive styles */
@media (max-width: 768px) {
  .modal-body .input {
    font-size: 14px;
  }

  .modal-footer .btn {
    width: 100%;
  }
}
