.terms-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .terms-container h1 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .terms-section {
    margin-bottom: 20px;
  }
  
  .terms-section h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .terms-section p {
    white-space: pre-wrap;
    line-height: 1.6;
    font-size: 16px;
    color: #333;
  }
  